import React, {lazy, Suspense} from "react";
import {useAccountStore} from "../../stores/useAccountStore";
import {Route, Switch} from "react-router-dom";

export const Routes = React.memo(() => {
    const {account} = useAccountStore();
    const {resources} = account;
    const routes = resources.filter(_ => !!_.component_path);
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Switch>
                {routes.map((item, index) => {
                    return <Route key={index}
                                  exact
                                  path={item.path}
                                  component={React.memo(lazy(() => import("../../containers/" + item.component_path)))}></Route>
                })}
            </Switch>
        </Suspense>
    );
});
