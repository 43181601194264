import React from 'react';
import './App.css';
import Login from './containers/login/login';
import {useAccountStore} from "./stores/useAccountStore";
import AppComp from './components/app/app';


const App = () => {
    const {account} = useAccountStore();

    if (!account) {
        return (<Login/>);
    }
    return (
        <AppComp/>
    );
}
export default App;
