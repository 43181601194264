import React from 'react';
import * as AntdIcons from '@ant-design/icons';

const AntdIcon = ({name, ...props}) => {
    if(!name) return null;
    const SelectedIcon = AntdIcons[name];
    return (
        <SelectedIcon {...props}/>
    )
}

export default AntdIcon;
