import React, {memo, useEffect, useState} from "react";
import {useAccountStore} from "../../stores/useAccountStore";
import {matchPath, useHistory} from "react-router-dom";
import styles from "./app.module.css";
import logo from "../../assets/images/logo.png";
import AntdIcon from "./antd_icon";
import {Divider} from "antd";
import ArrowIcon from "./arrow_icon";


/**
 * 菜单组件
 * @param items
 * @param onClick
 * @param openKeys
 * @param selectedKeys
 * @param onSelect
 * @param onOpenChange
 * @constructor
 */
const MyMenu = ({items, onClick, openKeys = [], selectedKeys = 0, onSelect, onOpenChange}) => {
    // console.log(items);

    // useEffect(() => {
    //     console.log(selectedKeys);
    //     setOpenKeys([...openKeys]);
    // }, [selectedKeys])

    const renderItem = (item, key, left = 18) => {
        // console.log(item);
        if (item.children && item.children.length > 0) {
            return (
                <div key={key}
                     className={`${styles.submenu}`}

                >
                    <div className={`${styles.submenutitle}`} onClick={() => {
                        if (openKeys.includes(item.key)) {
                            onOpenChange && onOpenChange(openKeys.filter(_ => _ !== item.key));
                        } else {
                            onOpenChange && onOpenChange([...openKeys, item.key]);
                        }
                        onClick && onClick(item);
                    }}
                         style={{
                             paddingLeft: left
                         }}
                    >

                        {item.icon && <AntdIcon name={item.icon} style={{marginRight: 10}}/>}
                        <div className={styles.title_content}>
                            {item.label}
                        </div>
                        <ArrowIcon style={{
                            marginLeft: 6,
                            transition: 'transform 0.3s',
                            transform: openKeys.includes(item.key) ? 'rotate(180deg)' : 'rotate(0deg)',
                        }}/>
                    </div>
                    <div className={`${openKeys.includes(item.key) ? null : styles.hidden}`}
                        // style={{
                        //     paddingLeft: left
                        // }}
                    >
                        {item.children.map((_, index) => renderItem(_, index, left + 24))}
                    </div>
                </div>
            )
        } else {
            return (
                <div key={key}
                     className={`${styles.menu_item2} ${selectedKeys === item.key ? styles.selected : null}`}
                     onClick={() => {
                         // setSelectedKeys([item.key]);
                         onClick && onClick(item);
                         onSelect && onSelect(item);
                     }}
                     style={{
                         paddingLeft: left
                     }}
                >
                    {item.icon && <AntdIcon name={item.icon} style={{marginRight: 10}}/>}
                    {item.label}
                </div>
            )
        }
    }
    return (
        <div className={styles.menu}>
            {items.map((item, index) => renderItem(item, index))}
        </div>
    )
}

export const Menus = memo(({collapsed}) => {
    const history = useHistory();
    const {account} = useAccountStore();
    const {resources} = account;
    const [menu1, setMenu1] = useState(0);
    const [menu2, setMenu2] = useState(0);
    const [menu2title, setMenu2Title] = useState("");
    const [menu2resources, setMenu2Resources] = useState([]);
    const [openKeys, setOpenKeys] = useState([]);

    /**
     * 根据路径查找资源
     * @param path
     * @returns {*}
     */
    const findResources = (path) => {
        // 查找该资源
        let item = resources.find(_ => _.path === path);
        if (!item) {
            for (let i = 0; i < resources.length; i++) {
                if (matchPath(path, {
                    path: resources[i].path
                }) != null) {
                    if (!item || resources[i].path.length > item.path.length) {
                        item = resources[i];
                    }
                }
            }
        }
        // console.log(item);
        return item;
    }

    /**
     * 当路由发生变化
     * @param route
     */
    const routeChange = (route) => {
        // console.log(route); // 这个route里面有当前路由的各个参数信息
        // setPath(route.pathname)
        let item = findResources(route.pathname);
        if (item) {
            // setMenu2(item.id);

            // 设置二级菜单和一级菜单
            let first = null, second = null,flag=false;
            while (item.pid !== 0) {
                if(item.type===0 && !flag){
                    setMenu2(item.id);
                    flag=true;
                }

                first = resources.find(_ => _.id === item.pid);
                second = item;
                item = first;

            }
            // setMenu2(second.id);
            setMenu1(first.id);
            setMenu2Title(first.title);
            // setMenu2Title(first.title);
        }
    }

    /**
     * 路由监听
     */
    useEffect(() => {
        // 路由监听
        let historyinstance = history.listen(routeChange);
        routeChange(history.location)
        return () => {
            // 取消监听
            historyinstance && historyinstance();
        }
    }, []);

    /**
     * 将列表转成多级树结构
     * [
     *   { label: '菜单项一', key: 'item-1' }, // 菜单项务必填写 key
     *   { label: '菜单项二', key: 'item-2' },
     *   {
     *     label: '子菜单',
     *     key: 'submenu',
     *     children: [{ label: '子菜单项', key: 'submenu-item-1' }],
     *   },
     * ]
     * @param resources
     * @returns {*[]}
     */
    const listToTree = (resources, pid) => {
        // console.log(resources, pid);
        let map = {}, val, arr = [], temp;
        for (let i = 0; i < resources.length; i++) {
            val = resources[i];
            map[val.id] = i;
            val.children = [];
            val.label = val.title;
            // if(val.icon){
            //     val.icon = (<AntdIcon name={val.icon}/>)
            // }else{
            //     val.icon = null
            // }
            val.key = val.id;
            if (val.pid === pid) {
                arr.push(val);
            }
        }
        for (let i = 0; i < resources.length; i++) {
            val = resources[i];
            temp = resources[map[val.pid]];
            if (temp) {
                temp.children.push(val);
            }
        }
        // console.log(arr)
        return arr;
    }


    useEffect(() => {
        // let items = resources.filter(m => m.pid === menu1);
        // 全部展开
        setOpenKeys(resources.filter(m => m.pid === menu1).map(_ => _.id));
        setMenu2Resources(listToTree(resources.filter(_ => _.type === 0), menu1));
    }, [menu1])

    return (
        <>
            {/*一级菜单*/}
            <div className={styles.menu1}>
                {/*logo*/}
                <div className={styles.logo}>
                    <img src={logo} alt={"logo"}/>
                </div>
                {/*菜单*/}
                <div className={styles.menu}>
                    {resources.filter(m => m.pid === 0).map((item, index) => {
                        return <div key={index}
                                    className={`${styles.menu_item} ${item.id === menu1 ? styles.selected : ""}`}
                                    onClick={() => {
                                        // 取出子节点的第一项
                                        let chd = resources.find(_ => _.pid === item.id);
                                        if (chd) {
                                            history.push(chd.path);
                                        }
                                    }}
                        >
                            <AntdIcon name={item.icon}/>
                            {item.title}
                        </div>
                    })}
                </div>
            </div>
            {/*二级菜单*/}
            <div className={styles.menu2} style={{
                display: collapsed ? "none" : "block",
            }}>
                <div className={styles.title}>小码蚁编程管理后台</div>
                <Divider plain style={{margin: 0}}>{menu2title}</Divider>
                <MyMenu
                    key={menu2}
                    items={menu2resources}
                    selectedKeys={menu2}
                    openKeys={openKeys}
                    onSelect={(item) => {
                        history.push(item.path);
                    }}
                    onOpenChange={(keys)=>{
                        setOpenKeys(keys)
                    }}
                />

                {/*<div className={styles.menu}>*/}
                {/*    {menu2resources.map((item, index) => {*/}
                {/*        return <div key={index}*/}
                {/*                    className={`${styles.menu_item2} ${item.id === menu2 ? styles.selected : ""}`}*/}
                {/*                    onClick={() => {*/}
                {/*                        history.push(item.path)*/}
                {/*                    }}>*/}
                {/*            {item.icon && <AntdIcon name={item.icon} style={{marginRight: 6}}/>}*/}
                {/*            {item.title}*/}
                {/*        </div>*/}
                {/*    })}*/}
                {/*</div>*/}
            </div>
        </>
    )
})
