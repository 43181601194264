import React, {useState} from 'react';
import {Dropdown} from "antd";
import {useAccountStore} from "../../stores/useAccountStore";
import {usePasswordModalStore} from "../../stores/usePasswordModalStore";
import ArrowIcon from "./arrow_icon";

const AccountMenu = () => {
    const {account} = useAccountStore();
    const {signOut} = useAccountStore();
    const {showPasswordModal} = usePasswordModalStore();
    const [isOpen, setIsOpen] = useState(false);

    const handleVisibleChange = (visible) => {
        setIsOpen(visible);
    };

    return (
        <Dropdown menu={{
            items: [
                {label: (<a onClick={showPasswordModal}>修改密码</a>), key: 'item-1'},
                {label: (<a onClick={signOut}>退出登录</a>), key: 'item-2'},
            ]
        }}
                  onOpenChange={handleVisibleChange}
        >
            <div style={{padding: '0 18px', cursor: 'pointer'}}>
                {account.username}
                <ArrowIcon style={{
                    marginLeft: 6,
                    transition: 'transform 0.3s',
                    transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                }}/>
            </div>
        </Dropdown>
    )
}

export default AccountMenu;
