import axios from 'axios';
import {message} from "antd";
import {useAccountStore} from "../stores/useAccountStore";


const instance = axios.create({
    baseURL: window.location.host.indexOf('localhost') > -1 ? "http://localhost:4003/api" : `${window.location.protocol}//${window.location.host}/api`
})
let finished = false;

const AxiosInterceptor = ({children}) => {

    if(!finished){

        const resInterceptor = response => {
            const data = response.data;

            console.log(data);

            if (data.code === 1 && data.message) {
                message.error(data.message)
                // toast.error(data.message)
            }

            return data;
        }

        const errInterceptor = error => {

            if (error && error.response) {
                if (error.response.data && error.response.data.code === 1) {
                    message.error(error.response.data.message);
                } else {
                    // message.error(error.message);
                }
                switch (error.response.status) {
                    case 400:
                        error.message = '请求错误';
                        break;

                    case 401:
                        error.message = '未授权，请登录';
                        useAccountStore.getState().signOut();
                        // useAccountStore().getState().signOut();
                        // store.dispatch(showLoginModal());
                        // store.dispatch(signOut());
                        // jumpToAuth();
                        // window.location.href = config.oauth2.url_auth
                        return Promise.resolve(error.response.data);
                    case 403:
                        error.message = '拒绝访问';
                        break;

                    case 404:
                        error.message = `请求地址出错: ${error.response.config.url}`;
                        break;

                    case 408:
                        error.message = '请求超时';
                        break;

                    case 500:
                        error.message = '服务器内部错误';
                        break;

                    case 501:
                        error.message = '服务未实现';
                        break;

                    case 502:
                        error.message = '网关错误';
                        break;

                    case 503:
                        error.message = '服务不可用';
                        break;

                    case 504:
                        error.message = '网关超时';
                        break;

                    case 505:
                        error.message = 'HTTP版本不受支持';
                        break;

                    default:
                }
            }


            return Promise.reject(error);
        }


        const interceptor = instance.interceptors.response.use(resInterceptor, errInterceptor);

        const interceptorrequest = instance.interceptors.request.use(config => {
            config.headers['X-Requested-With'] = 'XMLHttpRequest';
            // console.log('request begin');
            return config;
        })

        finished = true;

    }

    return children;
}

export default instance;
export {AxiosInterceptor}
