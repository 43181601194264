import styles from "./app.module.css";
import AntdIcon from "./antd_icon";
import React, {useEffect, useState} from "react";
import {Breadcrumb} from 'antd';
import {useAccountStore} from "../../stores/useAccountStore";
import {Link, matchPath, useHistory} from "react-router-dom";


const MyBreadCrumb = () => {
    const history = useHistory();
    const {account} = useAccountStore();
    const {resources} = account;
    const [list, setList] = useState([]);

    /**
     * 根据路径查找资源
     * @param path
     * @returns {*}
     */
    const findResources = (path) => {
        // 查找该资源
        let item = resources.find(_ => _.path === path);
        if (!item) {
            for (let i = 0; i < resources.length; i++) {
                if (matchPath(path, {
                    path: resources[i].path
                }) != null) {
                    if (!item || resources[i].path.length > item.path.length) {
                        item = resources[i];
                    }
                }
            }
        }
        return item;
    }

    /**
     * 当路由发生变化
     * @param route
     */
    const routeChange = (route) => {
        // console.log(route); // 这个route里面有当前路由的各个参数信息
        // setPath(route.pathname)
        let item = findResources(route.pathname);
        let list = [];
        while (item) {
            list.unshift(item);
            item = resources.find(_ => _.id === item.pid);
        }
        setList(list);
    }

    /**
     * 路由监听
     */
    useEffect(() => {
        // 路由监听
        let historyinstance = history.listen(routeChange);
        routeChange(history.location)
        return () => {
            // 取消监听
            historyinstance && historyinstance();
        }
    }, []);


    if (list.length === 0) return null; // 如果没有父节点，则不显示面包屑
    return (
        <Breadcrumb separator=">">
            {list.map((item, index) => (
                <Breadcrumb.Item key={index}>
                    {item.type === 0 && item.path
                        ? <Link to={item.path}>
                            {item.icon && <AntdIcon name={item.icon} style={{marginRight: 5}}/>}
                            {item.title}
                        </Link>
                        : <>
                            {item.icon && <AntdIcon name={item.icon} style={{marginRight: 5}}/>}
                            {item.title}
                        </>
                    }

                </Breadcrumb.Item>
            ))}
        </Breadcrumb>
    );
}

export default MyBreadCrumb;
