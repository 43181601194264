import React from 'react';
import Icon from "@ant-design/icons";

const ArrowSvg = () => (
    <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
         width="1em" height="1em">
        <path
            d="M533.333333 631.466667L866.133333 298.666667l29.866667 29.866666-362.666667 362.666667L170.666667 328.533333l29.866666-29.866666 332.8 332.8z"
            fill="#444444"></path>
    </svg>
)

const ArrowIcon = (props) => (
    <Icon component={ArrowSvg} {...props}/>
)

export default ArrowIcon;
