import React, {useState} from 'react';
import styles from "./app.module.css";
import MyBreadCrumb from "./breadcrumb";
import {MenuFoldOutlined, MenuUnfoldOutlined} from "@ant-design/icons";
import {Routes} from "./routes";
import {Menus} from "./menus";
import AccountMenu from "./account_menu";
import Change_password from "../../containers/change_password";

const App = () => {
    const [collapsed, setCollapsed] = useState(false);

    return (
        <div className={`${styles.layout} ${styles.layout_has_sider}`}>
            <div className={styles.sider} style={{
                flex: collapsed ? "0 0 64px" : "0 0 265px"
            }}>
                <Menus collapsed={collapsed}/>
            </div>
            <div className={styles.layout}>
                <div className={styles.header}>
                    {/*收缩*/}
                    {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                        className: styles.trigger,
                        onClick: () => setCollapsed(!collapsed),
                    })}
                    <div style={{flex: 1}}>
                        {/*面包屑导航*/}
                        <MyBreadCrumb/>
                    </div>
                    {/*用户信息*/}
                    <AccountMenu/>
                </div>
                <div className={styles.content}>
                    <Routes/>
                </div>
            </div>
            <Change_password/>
        </div>
    );
};

export default App;
